import React from 'react';
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Moment } from "moment";

interface CustomDatePickerProps {
    label: string;
    value: Moment | null;
    onChange: (date: Moment | null) => void;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ label, value, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                label={label}
                value={value}
                onChange={onChange}
                slotProps={{
                    field: { clearable: true },
                    textField: { variant: 'outlined' }
                }}
            />
        </LocalizationProvider>
    );
};

export default CustomDatePicker;
