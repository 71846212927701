import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../types';

//TOOD fix Element type
const PrivateRoutes = () => {
    const token = useSelector((state: RootState) => state.auth?.user?.token || null);

    return (token ? <Outlet /> : <Navigate to="/login" />);

};

export default PrivateRoutes;