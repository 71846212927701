import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomContentProps as NotistackCustomContentProps, useSnackbar } from 'notistack';
// import { isNil } from 'lodash';

const StyledCard = styled(Card)(({ theme }) => ({
	width: 350,
}));

const actionRootStyles = {
	padding: '8px 8px 8px 16px',
	justifyContent: 'space-between',
};

const stylesByVariant = {
	error: {
		backgroundColor: '#C23E38',
		color: 'white',
	},
	warning: {
		backgroundColor: '#F2A43A',
	},
	success: {
		backgroundColor: '#5D9E52',
	},
	info: {
		backgroundColor: '#4178F6',
	},
	default: {
		backgroundColor: '#4178F6',
	},
};

const iconsStyles = {
	marginLeft: '0.25rem',
};

const expandStyles = ({
	padding: '8px 8px',
	transform: 'rotate(0deg)',
	// transition: theme.transitions.create('transform', {
	// 	duration: theme.transitions.duration.shortest,
	// }),
});

const expandOpenStyles = {
	transform: 'rotate(180deg)',
};

const collapseStyles = {
	padding: 16,
};

export interface CustomSnackbarProps extends NotistackCustomContentProps {
	extra?: {
		[index: string]: string[] | string;
	};
}

const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
	const { id, message, variant = 'info', extra } = props;
	const { closeSnackbar } = useSnackbar();
	const [expanded, setExpanded] = useState(false);

	const handleExpandClick = () => setExpanded(!expanded);
	const handleDismiss = () => closeSnackbar(id);

	const iconAndFontColor = variant === 'warning' ? 'black' : 'white';

	const showExtra = () => {
		if (extra && extra !== null) {
			return Object.keys(extra).map((key, index) => (
				<Typography display={'block'} key={index} variant="caption" gutterBottom>
					{Array.isArray(extra[key])
						? (extra[key] as string[]).map((errorString, idx) => (
							<span key={idx}>
								{errorString}
								<br />
							</span>
						))
						: extra[key]}
				</Typography>
			));
		}

		return null;
	};

	return (
		<StyledCard ref={ref} style={stylesByVariant[variant]}>
			<CardActions style={actionRootStyles}>
				<Typography variant="subtitle2" style={{ color: iconAndFontColor }}>
					{message}
				</Typography>

				<div style={iconsStyles}>
					{extra && Object.keys(extra).length > 0 && (
						<IconButton
							aria-label="Show more"
							style={{
								...expandStyles,
								...(expanded && expandOpenStyles),
							}}
							onClick={handleExpandClick}
						>
							<ExpandMoreIcon style={{ color: iconAndFontColor }} />
						</IconButton>
					)}

					<IconButton style={expandStyles} onClick={handleDismiss}>
						<CloseIcon style={{ color: iconAndFontColor }} />
					</IconButton>
				</div>
			</CardActions>

			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<Paper style={collapseStyles}>{showExtra()}</Paper>
			</Collapse>
		</StyledCard>
	);
});

export default CustomSnackbar;
