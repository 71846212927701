import { useState } from "react";
import styled, { keyframes } from "styled-components";
import closePanelIcon from '../assets/close-panel.svg';
import { SidePanelInterface } from "../types";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Adjust to use max-width for larger screens and 100% width for mobile
const fadeIn = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100vw;
  }
`;

const slideOut = keyframes`
  from {
    width: 100vw;
  }
  to {
    width: 0;
  }
`;

const blurFadeIn = keyframes`
  from {
    backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(1.5px);
  }
`;

const blurFadeOut = keyframes`
  from {
    backdrop-filter: blur(1.5px);
  }
  to {
    backdrop-filter: blur(0px);
  }
`;

const Wrapper = styled.div<{ $isVisible: boolean }>`
  width: 100vw;
  height: 100vh;
  backdrop-filter: ${prop => prop.$isVisible ? 'blur(1px)' : 'blur(0px)'};
  background: rgba(15, 20, 25, 0.25);
  inset: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  animation: ${prop => prop.$isVisible ? blurFadeIn : blurFadeOut} 0.1s ease-out;
`;

const RightPanel = styled.div<{ $isVisible: boolean }>`
  padding: 20px;
  max-width: 100%;
  background-color: white;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  animation: ${prop => prop.$isVisible ? fadeIn : slideOut} 0.3s ease-out;

  // For larger screens, limit the width of the panel
  @media (min-width: 768px) {
    width: 50vw;
    max-width: 520px;
  }

  // Full width for smaller screens
  @media (max-width: 768px) {
    width: 100vw;
  }
`;

const PanelHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  box-sizing: border-box;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  width: 50px;
  height: 40px;
  cursor: pointer;
`;

export default function SidePanel({
  isVisible,
  handleHidePanel,
  children
}: SidePanelInterface) {
  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  if (!isVisible && !isAnimating) {
    return null;
  }

  const onAnimationEnd = () => {
    setIsAnimating(false);
  };

  const handleClose = () => {
    setIsAnimating(true);
    handleHidePanel();
  };

  return (
    <Wrapper $isVisible={isVisible} onClick={handleClose}>
      <RightPanel $isVisible={isVisible} onAnimationEnd={onAnimationEnd} onClick={(e) => e.stopPropagation()}>
        <PanelHeader>
          <CloseButton onClick={handleClose}>
            <ArrowForwardIcon />
          </CloseButton>
        </PanelHeader>
        <div style={{ overflow: 'auto', width: '100%' }}>
          {children}
        </div>
      </RightPanel>
    </Wrapper>
  );
}
