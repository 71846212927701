import {
    LOGIN,
} from '../actions';
import initialState from '../initialState';
import { AnyAction } from 'redux';
import { RootState } from '../../types';

const INITIAL_STATE = {
    ...initialState.auth,
};

export default (state: RootState['auth'] = INITIAL_STATE, action: AnyAction) => {

    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                ...action.payload,
            };


        default:
            return state;
    }
};
