import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import * as routes from '../utils/routes';
import "../index.css";
import apiClient from '../services/apiClient';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

const Bar = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 10px 20px;
    padding-top: 40px;
    box-sizing: border-box;
    box-shadow: 0px 3px 4px 0px rgb(221 232 235 / 61%);
    border-bottom: 1px solid #dfedf3;
    z-index: 1;  /* Set lower than Header */
    transition: transform 300ms ease-in-out;

    @media (max-width: 768px) {
        transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(-100%)')};
        width: 100%;
    }

    @media (min-width: 768px) {
        transform: none; /* Always visible for larger screens */
    }
`;

const Header = styled.header`
    position: fixed;
    width: 100vw;
    display: flex;
    z-index: 3;  /* Set higher than Bar */
    @media (min-width: 768px) {
        display: none; /* Hide button for larger screens */
    }
`;

const ToggleButton = styled.button`
    z-index: 1000;
    color: black;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    @media (min-width: 768px) {
        display: none; /* Hide button for larger screens */
    }
`;

const Logo = styled.p`
    font-size: 30px;
    margin-bottom: 50px;
    font-family: 'Noto Sans', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    color: black;
    @media (max-width: 768px) {
        display: none; /* Hide logo for smaller screens */
    }
`;

const NavItems = styled.div`
    display: flex;
    flex-direction: column;
`;

const NavItem = styled.div<{ $isActive: boolean }>`
    font-size: 14px;
    text-decoration: none;
    color: ${props => (props.$isActive ? '#0061ff' : 'black')};
    background-color: ${props => (props.$isActive ? '#e0f9ff' : 'transparent')};
    padding: 15px 25px;
    margin-bottom: 5px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 200ms, color 100ms;

    &:hover {
        background-color: #e0f9ff;
        color: #0061ff;
    }
`;

const LogoutButton = styled.div`
    font-size: 14px;
    text-decoration: none;
    color: black;
    padding: 15px 25px;
    margin-bottom: 5px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 200ms, color 100ms;
    user-select: none;
    cursor: pointer;

    &:hover {
        color: #0061ff;
    }
`;

const styles = {
    logoLink: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row' as 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '40px',
        textDecoration: 'none',
        fontSize: '35px',
        color: 'black'
    },
    link: {
        fontSize: '14px',
        textDecoration: 'none',
        color: 'black'
    }
};

interface NavItemProp {
    label: string;
    route: string;
}

export default function NavBar() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    const navSections = [
        [{ label: "Dashboard", route: routes.DASHBOARD }],
        [{ label: "Products", route: routes.PRODUCTS }, { label: "Inventory", route: routes.INVENTORY }, { label: "Orders", route: routes.ORDERS }],
        [{ label: "Expenses", route: routes.EXPENSES }]
    ];

    const handleLogout = () => {
        apiClient.auth.logout();
    };

    const toggleNavBar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        if (window.innerWidth <= 768) {
            setIsOpen(false);  // Close the NavBar on small screens
        }
    };

    return (
        <>
            <Header className="bg-white border-b border-b-gray-300 p-2 flex justify-between items-center">
                <ToggleButton className="text-black font-bold py-2 px-4 rounded" onClick={toggleNavBar}>
                    {isOpen ? <CloseIcon /> : <MenuIcon />}
                </ToggleButton>
                <div className="text-black">VendReady</div>
            </Header>

            <Bar isOpen={isOpen}>
                <Link to={routes.DASHBOARD} style={styles.logoLink} onClick={handleLinkClick}>
                    <Logo>VendReady</Logo>
                </Link>
                <NavItems>
                    {navSections.map((section: NavItemProp[], index: number) => (
                        <div key={index}>
                            {section.map((navItem: NavItemProp) => (
                                <React.Fragment key={navItem.route}>
                                    <Link
                                        to={navItem.route}
                                        style={styles.link}
                                        onClick={handleLinkClick}  // Close the NavBar after click
                                    >
                                        <NavItem $isActive={location.pathname === navItem.route}>
                                            {navItem.label}
                                        </NavItem>
                                    </Link>
                                </React.Fragment>
                            ))}
                            <div style={{ margin: '15px auto', height: '1px', backgroundColor: '#eff0f4', width: '70%' }}></div>
                        </div>
                    ))}
                </NavItems>
                <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
            </Bar>
        </>
    );
}
