import { Box } from "@mui/material";
import { DataGrid, GridOverlay, GridToolbar } from "@mui/x-data-grid";

function CustomNoRowsOverlay() {
    return (
        <GridOverlay>
            <div className="p-5 text-center text-gray-400">
                Empty
            </div>
        </GridOverlay>
    );
}

export default function Table(props: any) {
    const { objects, columns, onRowClick } = props;

    const renderCellContent = (column: any, object: any) => {
        if (column.renderCell) {
            // Call renderCell function if defined
            return column.renderCell({ row: object, value: object[column.field] });
        } else {
            // Otherwise return the normal field value
            return object[column.field];
        }
    };

    return (
        <>
            {/* DataGrid for large screens */}
            <div className="custom-header-container w-full h-[82vh] bg-white shadow-xl rounded-lg">
                <DataGrid
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    getCellClassName={(params) =>
                        ['name', 'product_name', 'customer_name', 'title'].includes(params.field)
                            ? 'font-sans p-6 font-medium text-gray-900 whitespace-nowrap'
                            : 'font-sans px-6 py-4 text-gray-500 whitespace-nowrap'
                    }
                    rows={objects}
                    columns={columns}
                    pageSizeOptions={[5, 10, 25]}
                    className="h-full w-full"
                    rowHeight={50}
                    columnHeaderHeight={50}
                    onRowClick={onRowClick} // Handle row click for the table
                    slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
                    {...props}
                />
            </div>

            {/* Card layout for small screens */}
            <Box className="block md:hidden w-full" sx={{ marginTop: '30px' }}>
                {objects.map((object: any, index: number) => (
                    <div
                        key={index}
                        className="bg-white shadow-lg rounded-lg p-4 mb-4 cursor-pointer"
                        onClick={() => onRowClick({ row: object })} // Trigger row click on card click
                    >
                        {/* Render the first column value larger */}
                        <div className="text-2xl font-bold mb-2">
                            {renderCellContent(columns[0], object)}
                        </div>

                        {/* Render the rest of the columns with field names */}
                        {columns.slice(1).map((column: any) => (
                            <div key={column.field} className="text-base text-gray-700">
                                <strong>{column.headerName}: </strong>
                                {renderCellContent(column, object)}
                            </div>
                        ))}
                    </div>
                ))}
            </Box>
        </>
    );
}
