import { AnyAction, combineReducers } from 'redux';
import { LOGOUT } from '../actions';
import { RootState } from '../../types';
import initialState from '../initialState';
import authReducer from './authReducer';
import notificationReducer from './notificationReducer';

const appReducer = combineReducers({
    auth: authReducer,
    notifications: notificationReducer,
});

export default (state: RootState | undefined, action: AnyAction) => {
    if (action.type === LOGOUT) {
        state = initialState;
    }

    return appReducer(state, action);
};
