import { AppStartListening } from './listenerMiddleware';
import { instance } from '../../services/apiClient';

export default (startListening: AppStartListening) => {
	// Listener to set the access_token of the currently logged in admin/super-admin
	startListening({
		predicate: (action, currentState, previousState) => {
			return (currentState.auth?.user?.token !== previousState.auth?.user?.token)
		},
		effect: async (action, listenerApi) => {
			const {
				auth: { user },
			} = listenerApi.getState();

			const access_token = user?.token;
			const shop_hash_id = user?.shop_hash_id;

			if (access_token && shop_hash_id) {
				instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
				instance.defaults.headers.common['x-shop'] = shop_hash_id;
			} else {
				console.error('Access Token or shop id missing');
			}
		},
	});

	// Listener to set the `X-Admin-Institution` header
	// and fetch currently logged in admin's subscriptions & permissions
	// startListening({
	// 	predicate: (action, currentState, previousState) => {
	// 		return currentState.auth?.institution?.hash_id !== previousState.auth?.institution?.hash_id;
	// 	},
	// 	effect: async (action, listenerApi) => {
	// 		const {
	// 			auth: { institution },
	// 		} = listenerApi.getState();
	// 		const institutionHashID = institution?.hash_id || null;

	// 		const addPermissions = (permissions: PermissionState[]) =>
	// 			listenerApi.dispatch(addPermissionsAction(permissions));
	// 		const updateSubscriptions = (subscriptions: SubscriptionModel[]) =>
	// 			listenerApi.dispatch(updateSubscriptionAction(subscriptions));

	// 		const fetchPermissions = async () => {
	// 			try {
	// 				const permissions = await apiClient.get('/getPermissions');
	// 				addPermissions(permissions);
	// 			} catch (e) {
	// 				addPermissions([]);
	// 			}
	// 		};

	// 		const fetchSubscription = async () => {
	// 			try {
	// 				const subscriptions = await apiClient.get('/subscriptions');
	// 				if (subscriptions.length > 0) {
	// 					updateSubscriptions(subscriptions);
	// 				} else {
	// 					updateSubscriptions([]);
	// 				}
	// 			} catch (e) {
	// 				updateSubscriptions([]);
	// 				if (!(e instanceof ApiClientError)) {
	// 					Sentry.captureException(e);
	// 				}
	// 			}
	// 		};

	// 		if (institutionHashID) {
	// 			instance.defaults.headers.common['X-Admin-Institution'] = `${institutionHashID}`;
	// 			fetchPermissions();
	// 			fetchSubscription();
	// 		} else {
	// 			console.error('No Institution Hash ID found!');
	// 		}
	// 	},
	// });
};
