import { ExpenseInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import { CircularProgress, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { InputField, Label, ModalButton, ModalTitle, Select, TextArea } from "./SharedComponents";
import apiClient from "../services/apiClient";

interface ExpenseModalProp extends Omit<SidePanelInterface, 'children'> {
    expense?: ExpenseInterface | null
};

export default function ExpenseModal(props: ExpenseModalProp) {
    const { expense, handleHidePanel, ...sidePanelProps } = props;

    const [expenseName, setExpenseName] = useState<string>('');
    const [expenseDescription, setExpenseDescription] = useState<string | null>('');
    const [expensePrice, setExpensePrice] = useState<number>(0);
    const [expenseType, setExpenseType] = useState<"expense" | "purchase">("expense")
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (expense) {
            let valueFormatted = Number(Number(expense.price).toFixed(2));
            console.log("value formatted", valueFormatted, expense.price);

            setExpenseName(expense.title);
            setExpensePrice(valueFormatted);
            setExpenseType(expense.expense_type);

            if (expense.description)
                setExpenseDescription(expense.description);


        } else {
            setExpenseName("");
            setExpenseType("expense");
            setExpenseDescription("");
            setExpensePrice(0);
        }
    }, [expense])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setExpenseName(value);
        } else if (name === 'description') {
            setExpenseDescription(value);
        } else if (name === 'type') {
            setExpenseType(value as 'expense' | 'purchase');
        } else if (name === 'price') {
            let valueFormatted = Number(Number(value).toFixed(2));
            console.log("value formatted", valueFormatted, value);
            setExpensePrice(valueFormatted);
        }
    }

    const hidePanel = (expense: ExpenseInterface | null = null) => {
        setExpenseName("");
        setExpenseDescription("");
        setExpenseType("expense");
        setExpensePrice(0);
        if (expense) {
            handleHidePanel(expense);
        } else {
            handleHidePanel();
        }
    }

    const createUpdateExpense = (expense: ExpenseInterface, expenseID: number | null = null) => {
        let method = expenseID ? 'PUT' : 'POST';
        let url = `/expenses/${expenseID ? expenseID : ''}`;

        console.log("url", url, expense);

        if (method === 'PUT') {
            return apiClient.update(url, expense)
        }

        return apiClient.post(url, expense)

    }

    const isFieldsValid = () => {
        if (expenseName.length === 0) {
            alert("Name is required");
            return false;
        }
        if (expensePrice === 0) {
            alert("Price is required");
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if (!isFieldsValid()) return;

        setIsLoading(true);
        let expenseID: number | null | undefined = expense ? expense.id : null;

        const expenseJson: ExpenseInterface = {
            title: expenseName,
            // description: expenseDescription,
            price: expensePrice,
            expense_type: expenseType
        }

        createUpdateExpense(expenseJson, expenseID).then(() => {
            setIsLoading(false)
            if (expenseID) {
                expenseJson.id = expenseID;
            }
            hidePanel(expenseJson);
        }).finally(
            () => {
                setIsLoading(false)
            })

    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            <ModalTitle>Edit Expense</ModalTitle>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormGroup style={{ flex: 8, marginRight: '15px' }}>
                    <Label>Name</Label>
                    <InputField type="text" name="name" value={expenseName} onChange={handleChange} />
                </FormGroup>
                <FormGroup style={{ flex: 1, marginRight: '15px' }}>
                    <Label>Price</Label>
                    <InputField type="number" name="price" value={expensePrice} onChange={handleChange} />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                    <Label>Type</Label>
                    <Select name="type" value={expenseType} onChange={handleChange} >
                        <option value="expense">Expense</option>
                        <option value="purchase">Purchase</option>
                    </Select>
                </FormGroup>
            </div>
            <FormGroup>
                <Label>Description</Label>
                <TextArea name="description" value={expenseDescription || ""} onChange={handleChange} />
            </FormGroup>
            <ModalButton onClick={handleSave}>{
                isLoading ? <CircularProgress size={15} color="inherit" /> : 'Save'
            }</ModalButton>
        </SidePanel>
    )
}