import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import initialState from './initialState';
import rootReducer from './reducers/rootReducer';
import { configureStore } from '@reduxjs/toolkit';
import { listenerMiddleware } from './middleware/listenerMiddleware';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'analytics', 'superAuth', 'darkMode', 'toggles', 'resources', 'savedTables'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	preloadedState: initialState as any,
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}).prepend(listenerMiddleware.middleware)
		.concat(thunk),
});

export const persistor = persistStore(store);

export default store;
