import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import Tabbed from '../components/Tabbed';
import ProductInventory from '../components/ProductInventory';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    height:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
    @media (max-width: 768px) {
        padding:20px 20px;
    }
`;
export default function Inventory() {

    const [productInventoryCount, setProductInventoryCount] = React.useState<number>(0);

    return (
        <Wrapper>
            <Header
                title="Inventory"
                description="Create edit or delete inventory items here"
            />
            <div style={{ marginTop: '20px' }}>
                <Tabbed sections={[
                    {
                        label: `Products Stock (${productInventoryCount ? productInventoryCount : 0})`,
                        component: (<ProductInventory updateInventoryCount={setProductInventoryCount} />)
                    },
                    // {
                    //     label: "Materials Stock(1k)",
                    //     component: (<div></div>)
                    // }
                ]} />
            </div>
        </Wrapper>
    )
}