import React, { useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { GridColDef } from '@mui/x-data-grid';
import Table from '../components/Table';
import moment from 'moment';
import OrderModal from '../components/OrderModal';
import { OrderInterface } from '../types';
import apiClient from '../services/apiClient';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    height:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
    @media (max-width: 768px) {
        padding:20px 20px;
    }
`;

const CreateButton = styled.button`
    background-color:#0061FF;
    border:none;
    border-radius:10px;
    color:white;
    padding:8px 18px;
    height:40px;
    // padding: 0px 6px 0px 22px;
    font-size:15px;
    transition:250ms;
    cursor:pointer;
    &:hover{
        background-color:#0053D9;
        color:#BFDFFE;
    }
`

export default function Orders() {
    const [orders, setOrders] = React.useState<OrderInterface[]>([]);
    const [isEditPanelVisible, setIsEditPanelVisible] = React.useState<boolean>(false);
    const [selectedOrder, setSelectedOrder] = React.useState<OrderInterface | null>(null);

    useEffect(() => {
        apiClient.get('/orders').then((response: any) => {
            setOrders(response.data)
        })
    }, [])

    const columns: (GridColDef & { isNotSearchable?: boolean })[] = [
        { field: 'date_created', headerName: 'Date Ordered', width: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('YYYY-MM-DD'), flex: 1 },
        { field: 'customer_name', headerName: 'Ordered By', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'customer_phone', headerName: 'Phone', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'customer_address', headerName: 'Address', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'total_price', headerName: 'Total Price', width: 80, headerClassName: "table-header", cellClassName: 'thin-text', valueGetter: (params) => Number(params.value).toFixed(2), flex: 1 },
        { field: 'total_quantity', headerName: 'Total Quantity', width: 80, headerClassName: "table-header", cellClassName: 'thin-text', flex: 1 },
        // { field: 'date_updated', headerName: 'Last Updated', width: 150, headerClassName:"table-header", cellClassName:'thin-text',renderCell: (params) => moment(params.value).format('YYYY-MM-DD'), flex:1 },
    ];

    const handleRowClick = (order: any) => {
        setSelectedOrder(order);
        setIsEditPanelVisible(true);
    }

    const showCreateOrderPanel = () => {
        console.log("clicked")

        setSelectedOrder({
            customer_name: '',
            customer_email: '',
            customer_phone: '',
            customer_address: ''
        })
        setIsEditPanelVisible(true)
    }


    const hideOrderPanel = (order: OrderInterface | null | undefined = null) => {
        setIsEditPanelVisible(false);
        setSelectedOrder(null);

        if (order) {
            let isOrderNew = true;

            setOrders(orders.map((o: OrderInterface) => {
                if (o.id === order.id) {
                    isOrderNew = false;
                    return order;
                }
                return o;
            }))

            if (isOrderNew) {
                setOrders([...orders, order]);
            }
        }
    }

    return (
        <Wrapper>
            <Header
                title="Orders"
                description="Create edit or delete orders here"
                headerButtons={[<CreateButton onClick={showCreateOrderPanel}>Add Order</CreateButton>]}
            />
            <Table columns={columns} objects={orders} sx={{ marginTop: '50px' }} onRowClick={(param: any) => handleRowClick(param.row)} />
            {
                isEditPanelVisible && <OrderModal isVisible={isEditPanelVisible} handleHidePanel={hideOrderPanel} order={selectedOrder} />
            }
            {/* <OrderModal isVisible={isEditPanelVisible} handleHidePanel={hideCreateProductPanel} order={selectedOrder}/> */}
        </Wrapper>
    )
}