import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { GridColDef } from '@mui/x-data-grid';
import '../index.css';
import Table from '../components/Table';
import ProductModal from '../components/ProductModal';
import { ProductInterface } from '../types';
import moment from 'moment';
import apiClient from '../services/apiClient';
import TailWindTable from '../components/TailwindTable';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    height:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
    @media (max-width: 768px) {
        padding:20px 20px;
    }
`;

const CreateButton = styled.button`
    background-color:#0061FF;
    border:none;
    border-radius:10px;
    color:white;
    padding:8px 18px;
    font-size:15px;
    transition:250ms;
    cursor:pointer;
    &:hover{
        background-color:#0053D9;
        color:#BFDFFE;
    }
`

export default function Products() {
    const [isEditPanelVisible, setIsEditPanelVisible] = useState<boolean>(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductInterface | null>(null);
    const [products, setProducts] = useState<ProductInterface[]>([]);

    const columns: (GridColDef & { isNotSearchable?: boolean })[] = [
        { field: 'name', headerName: 'Name', width: 260, headerClassName: "table-header", cellClassName: 'bold-text', flex: 2 },
        { field: 'default_price', headerName: 'Default Price', width: 80, headerClassName: "table-header", cellClassName: 'thin-text', valueGetter: (params) => Number(params.value).toFixed(2), flex: 1 },
        // { field: 'created_by', headerName: 'Created By', width: 200, headerClassName:"table-header", cellClassName:'thin-text', flex:1},
        { field: 'date_created', headerName: 'Date Created', width: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('Do MMM YYYY'), flex: 1 },
        { field: 'date_updated', headerName: 'Last Updated', width: 150, headerClassName: "table-header", cellClassName: 'thin-text', renderCell: (params) => moment(params.value).format('Do MMM YYYY'), flex: 1 },
    ];

    useEffect(() => {
        apiClient.get('/products').then((response: any) => {
            console.log("fetched products", response.data);
            setProducts(response.data);
        })

    }, [])

    useEffect(() => {
        console.log("product updated", products);
    }, [products])

    const handleRowClick = (product: ProductInterface) => {
        setSelectedProduct(product);
        setIsEditPanelVisible(true);
    }

    const showCreateProductPanel = () => {
        setIsEditPanelVisible(true)
    }

    const hideCreateProductPanel = (product: ProductInterface | null = null) => {
        setIsEditPanelVisible(false);
        setSelectedProduct(null);

        //update this flow so i know if it's from a normal close or an actual api call
        if (product) {
            let isProductNew = true;

            setProducts(products.map((p: ProductInterface) => {
                if (p.id === product.id) {
                    isProductNew = false;
                    return product;
                }
                return p;
            }))

            if (isProductNew) {
                setProducts([...products, product]);
            }
        }
    }



    return (
        <Wrapper>
            <Header
                title="Products"
                description="Create edit or delete products here"
                headerButtons={[<CreateButton onClick={showCreateProductPanel}>Add New</CreateButton>]}
            />
            {/* <TailWindTable /> */}
            <Table columns={columns} objects={products} sx={{ marginTop: '50px' }} onRowClick={(param: any) => handleRowClick(param.row)} />
            <ProductModal isVisible={isEditPanelVisible} handleHidePanel={hideCreateProductPanel} product={selectedProduct} />
        </Wrapper>
    )
}