import React from 'react';
import OrderModalProductsList from "../orders/OrderModalProductsList";
import { FormGroup, Label, ModalTitle, ValueField } from '../SharedComponents';
import { CircularProgress } from '@mui/material';

interface Props {
    order: any;
    isOrderProductsLoading: boolean;
    products: any;
    totalOrderPrice: number | null;
}   

const OrderDetails = ({order, isOrderProductsLoading,products, totalOrderPrice}:Props) => {
    // Your component logic here

    return (
        <>
            <ModalTitle>Order Details</ModalTitle>
            <div style={{display:'flex', flexDirection:'row'}}>
                <FormGroup style={{flex:1}}>
                    <Label>Ordered By</Label>
                    <ValueField>{order?.customer_name}</ValueField>
                </FormGroup>
                {order?.customer_address && (
                    <FormGroup style={{flex:1}}>
                        <Label>Delivery Address</Label>
                        <ValueField>{order?.customer_address}</ValueField>
                    </FormGroup>
                ) }
            </div>
            <div style={{display:'flex', flexDirection:'row'}}>
                {order?.customer_phone && (
                    <FormGroup style={{flex:1}}>
                        <Label>Phone</Label>
                        <ValueField>{order?.customer_phone}</ValueField>
                    </FormGroup>
                )}
                {order?.customer_email && (
                    <FormGroup style={{flex:1}}>
                        <Label>Email</Label>
                        <ValueField>{order?.customer_email}</ValueField>
                    </FormGroup>
                )}
            </div>

            <hr style={{margin:'50px 0px 30px 0px', backgroundColor:'#f3f3f3', border:'none', height:'1px'}}/>

            <div>
                <h4>Products</h4>
                {
                    isOrderProductsLoading
                     ? <div style={{display:'flex',flexDirection:'row', justifyContent:'center', padding:'50px 0px'}}><CircularProgress size={30} color="inherit" /></div> : (
                        <OrderModalProductsList totalOrderPrice={totalOrderPrice} orderProducts={products} />
                    )
                }

                
            </div>
        </>
    );
};

export default OrderDetails;