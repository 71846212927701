import { NOTIFICATION_AUTO_HIDE_DURATION } from '../../utils/constants';
import initialState from '../initialState';
import { ADD_NOTIFICATION, CLOSE_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions';
import { AnyAction } from 'redux';
import { RootState } from '../../types';

const INITIAL_STATE = [...initialState.notifications];

export default (state: RootState['notifications'] = INITIAL_STATE, action: AnyAction) => {
	switch (action.type) {
		case ADD_NOTIFICATION:
			const key = action.notification.options && action.notification.options.key;

			return [
				...state,
				{
					...action.notification,
					options: {
						...action.notification.options,
						key: key || new Date().getTime() + Math.random(),
						autoHideDuration: NOTIFICATION_AUTO_HIDE_DURATION,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
					},
				},
			];

		case CLOSE_NOTIFICATION:
			return state.map((notification) =>
				action.dismissAll || notification.options.key === action.key
					? { ...notification, dismissed: true }
					: { ...notification }
			);

		case REMOVE_NOTIFICATION:
			return state.filter((notification) => notification.options.key !== action.key);

		default:
			return state;
	}
};
