import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';
import { RootState } from '../../types';
import authListenerMiddleware from './authListenerMiddleware';

export const listenerMiddleware = createListenerMiddleware();

// TS-TODO: Replace `any` with `AppDispatch` when we define it
export type AppStartListening = TypedStartListening<RootState, any>;

const startAppListening = listenerMiddleware.startListening as AppStartListening;

// Register listener middlewares here
authListenerMiddleware(startAppListening);
