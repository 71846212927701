import { addNotification } from '../redux/actions';
import store from '../redux/store';
import { CustomContentProps as NotistackCustomContentProps } from 'notistack';
import { CustomSnackbarProps } from '../components/CustomSnackbar';

export type NotificationKey = string | number;

export interface NotificationPayload {
    message: NotistackCustomContentProps['message'];
    options: Pick<NotistackCustomContentProps, 'variant' | 'persist'> & Pick<CustomSnackbarProps, 'extra'>;
}

export default (
    variant: NotificationPayload['options']['variant'],
    message: NotificationPayload['message'],
    extra?: NotificationPayload['options']['extra']
) => {
    if (!message) {
        switch (variant) {
            case 'success':
                message = 'Success!';
                break;
            case 'warning':
                message = "Something didn't go quite right...";
                break;
            case 'error':
                message = 'An error occurred!';
                break;
            default:
                message = 'Ok';
        }
    }

    store.dispatch(
        addNotification({
            message,
            options: {
                variant,
                extra,
                persist: extra ? Object.keys(extra).length > 0 : false,
            },
        })
    );
};
