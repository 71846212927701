export const LOGIN = '/login';
export const REGISTER = '/register';
export const FORGOTTEN_PASSWORD = '/forgotten-password';
export const RESET_PASSWORD = '/reset-password';

export const DASHBOARD = '/';
export const PRODUCTS = '/products/';
export const PRODUCTS_EDIT = '/products/edit/:id';
export const INVENTORY = '/inventory';
export const ORDERS = '/orders';
export const EXPENSES = '/expenses';
export const ASSETS = '/assests';
export const CERTIFICATIONS = '/certifications';