import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as routes from './utils/routes';
import Dashboard from "./pages/Dashboard";
import Login from './pages/Login';
import React from "react";
import Navigation from "./components/Navigation";
import Register from "./pages/Register";
import ForgottenPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import Products from "./pages/Products";
import Orders from "./pages/Orders";
import Inventory from "./pages/Inventory";
import Assets from "./pages/Assets";
import Expenses from "./pages/Expenses";
import PrivateRoutes from "./components/PrivateRoutes";
import CustomSnackbar from './components/CustomSnackbar';
import { SnackbarProvider } from "notistack";
import Notifier from "./components/Notifier";
import NotFound from "./pages/NotFound";
import { ThemeProvider } from "styled-components";
import { createTheme } from "@mui/material";

function App() {
    const theme = createTheme();

    return (
        <BrowserRouter>
            <SnackbarProvider
                Components={{
                    default: CustomSnackbar,
                    error: CustomSnackbar,
                    success: CustomSnackbar,
                    warning: CustomSnackbar,
                    info: CustomSnackbar,
                }}
            >
                <>
                    <ThemeProvider theme={theme}>
                        <Notifier />
                        <Navigation>
                            <Routes>
                                <Route element={<PrivateRoutes />} >
                                    <Route path={routes.DASHBOARD} element={<Dashboard />} />
                                    <Route path={routes.PRODUCTS} element={<Products />} />
                                    <Route path={routes.EXPENSES} element={<Expenses />} />
                                    <Route path={routes.ORDERS} element={<Orders />} />
                                    <Route path={routes.INVENTORY} element={<Inventory />} />
                                    <Route path={routes.ASSETS} element={<Assets />} />
                                </Route>
                                <Route path={routes.LOGIN} element={<Login />} />
                                <Route path={routes.REGISTER} element={<Register />} />
                                <Route path={routes.FORGOTTEN_PASSWORD} element={<ForgottenPassword />} />
                                <Route path={routes.RESET_PASSWORD} element={<ResetPassword />} />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </Navigation>
                    </ThemeProvider>
                </>
            </SnackbarProvider>
        </BrowserRouter>
    );
}

export default App;
