import React from "react"
import { ReactElement } from "react"
import styled from "styled-components"


const Wrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
`

const FirstRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
`

const Title = styled.p`
    margin:0px;
    font-size: 1.5rem;
    letter-spacing: 0px;
    line-height: 1.33333;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 500;
    `
    
const Description = styled.p`
    margin:0px;
    font-size:0.875rem;
    color:#4b5865;
`

const Buttons = styled.div`
    display:flex;
    flex-direction:row;
`

interface HeaderProps {
    title: string;
    description: string;
    headerButtons?: ReactElement[]
}

export default function Header({title, description, headerButtons}:HeaderProps){
    return(
        <Wrapper>
            <FirstRow>
                <Title>{title}</Title>
                <Buttons>
                    {
                        headerButtons && headerButtons.map((button, index)=>(React.cloneElement(button, { key: index })))
                    }
                </Buttons>
            </FirstRow>
            <Description>{description}</Description>
        </Wrapper>
    )
}