import { ProductInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import { Box, CircularProgress, FormGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { InputField, Label, ModalButton, ModalTitle, TextArea } from "./SharedComponents";
import apiClient from "../services/apiClient";

interface ProductModalProp extends Omit<SidePanelInterface, 'children'> {
    product?: ProductInterface | null
};

const fieldRowStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 768px)': {
        flexDirection: 'column'
    }
}

export default function ProductModal(props: ProductModalProp) {
    const { product, handleHidePanel, ...sidePanelProps } = props;

    const [productName, setProductName] = useState<string>('');
    const [productDescription, setProductDescription] = useState<string>('');
    const [productPrice, setProductPrice] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (product) {
            let valueFormatted = Number(Number(product.default_price).toFixed(2));
            console.log("value formatted", valueFormatted, product.default_price);

            setProductName(product.name);
            setProductDescription(product.description);
            setProductPrice(valueFormatted);
        } else {
            setProductName("");
            setProductDescription("");
            setProductPrice(0);
        }
    }, [product])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        if (name === 'name') {
            setProductName(value);
        } else if (name === 'description') {
            setProductDescription(value);
        } else if (name === 'default_price') {
            let valueFormatted = Number(Number(value).toFixed(2));
            console.log("value formatted", valueFormatted, value);
            setProductPrice(valueFormatted);
        }
    }

    const hidePanel = (product: ProductInterface | null = null) => {
        setProductName("");
        setProductDescription("");
        setProductPrice(0);
        if (product) {
            handleHidePanel(product);
        } else {
            handleHidePanel();
        }
    }

    const createUpdateProduct = (product: ProductInterface, productID: string | null = null) => {
        let method = productID ? 'PUT' : 'POST';
        let url = `/products/${productID ? productID : ''}`;

        if (method === 'PUT') {
            return apiClient.update(url, product)
        }

        return apiClient.post(url, product)
    }

    const isFieldsValid = () => {
        if (productName.length === 0) {
            alert("Name is required");
            return false;
        }
        if (productPrice === 0) {
            alert("Price is required");
            return false;
        }
        return true;
    }

    const handleSave = () => {
        if (!isFieldsValid()) return;

        setIsLoading(true);
        let productID = product ? product.id : null;

        const productJson: ProductInterface = {
            name: productName,
            description: productDescription,
            default_price: productPrice
        }

        createUpdateProduct(productJson, productID).then((result: any) => {
            console.log("updated", result)
            setIsLoading(false)
            hidePanel(result.data);
        });
    }

    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            <ModalTitle>Edit Product</ModalTitle>
            <Box sx={fieldRowStyle}>
                <FormGroup style={{ flex: 8, marginRight: '15px' }}>
                    <Label>Name</Label>
                    <InputField type="text" name="name" value={productName} onChange={handleChange} />
                </FormGroup>
                <FormGroup style={{ flex: 1 }}>
                    <Label>Default Price</Label>
                    <InputField type="number" name="default_price" value={productPrice} onChange={handleChange} />
                </FormGroup>
            </Box>
            <FormGroup>
                <Label>Description</Label>
                <TextArea name="description" value={productDescription} onChange={handleChange} />
            </FormGroup>
            <ModalButton onClick={handleSave}>{
                isLoading ? <CircularProgress size={15} color="inherit" /> : 'Save'
            }</ModalButton>
        </SidePanel>
    )
}