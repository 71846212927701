import { CloseReason, useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNotification } from '../redux/actions';
import { Notification, RootState } from '../types';

const Notifier = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const notifications = useSelector((state: RootState) => state.notifications);
	const displayed = useRef<Array<string | number>>([]);
	const currentSnacks = useRef<Array<Notification>>([]);

	const storeDisplayed = (id: string | number) => {
		displayed.current = [...displayed.current, id];
	};

	const alreadyDisplayed = (key: Notification['options']['key']) => {
		return displayed.current.includes(key);
	};

	useEffect(() => {
		const dispatchNewNotifications = () => {
			notifications.forEach(({ message, options }) => {
				if (alreadyDisplayed(options.key)) {
					return;
				}

				enqueueSnackbar(message, {
					...options,
					onClose: (event: SyntheticEvent<any> | null, reason: CloseReason) => {
						if (options.onClose) {
							options.onClose(event, reason);
						}
						dispatch(removeNotification(options.key));
					},
				});

				storeDisplayed(options.key);
			});
		};

		const newSnacks = notifications;
		if (!newSnacks.length) {
			displayed.current = [];
		}

		const presentSnacks = currentSnacks.current;

		let notExists = false;

		for (let i = 0; i < newSnacks.length; i += 1) {
			const newSnack = newSnacks[i];

			if (newSnack.dismissed) {
				closeSnackbar(newSnack.options.key);
				dispatch(removeNotification(newSnack.options.key));
			}

			if (notExists) continue;
			notExists =
				notExists ||
				!presentSnacks.filter(({ options: { key } }) => newSnack.options.key === key).length;
		}

		if (notExists) {
			dispatchNewNotifications();
			currentSnacks.current = [...notifications];
		}
	}, [notifications, dispatch, closeSnackbar, enqueueSnackbar]);

	return null;
};

export default Notifier;
