import { CircularProgress, Paper } from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import apiClient from "../services/apiClient";

const fetchProductStockingHistory = (productID: number) => {
    return apiClient.get(`/inventory/${productID}/stocking-history`);
}

const ProductStockHistoryList = ({ product }: any) => {
    const [stockHistory, setStockHistory] = useState<any>([]);
    const [isFetchingStockHistory, setIsFetchingStockHistory] = useState<boolean>(false);

    useEffect(() => {
        if (product) {
            setIsFetchingStockHistory(true);
            fetchProductStockingHistory(product.product_id).then((response: any) => {
                setStockHistory(response.data);
                setIsFetchingStockHistory(false);
            })
        }
    }, [product])

    return (
        <div>
            {
                isFetchingStockHistory ? <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '20px 0px' }}><CircularProgress /></div> : (
                    <>
                        {stockHistory.length > 0 && (
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ fontSize: '14px' }}>Batch No</div>
                                <div style={{ fontSize: '14px' }}>Quantity</div>
                                <div style={{ fontSize: '14px' }}>Expiration Date</div>
                                <div style={{ fontSize: '14px' }}>Date Created</div>
                            </div>
                        )}
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {
                                stockHistory && stockHistory.map((stockingHistoryItem: any, index: number) => {
                                    return (
                                        <Paper variant="outlined" key={stockingHistoryItem.id} style={{ marginBottom: '5px', padding: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '10px', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                <h4 style={{ margin: '0px', fontSize: '14px' }}>#{stockingHistoryItem.batch_no ?? "N/A"}</h4>
                                            </div>
                                            <div style={{ fontSize: '14px' }}>{stockingHistoryItem.quantity} stocked</div>
                                            <div style={{ fontSize: '14px' }}>{stockingHistoryItem.expiration_date ? moment(stockingHistoryItem.expiration_date).format('YYYY-MM-DD') : "N/A"}</div>
                                            <div style={{ fontSize: '14px' }}>{moment(stockingHistoryItem.date_created).format('YYYY-MM-DD')}</div>
                                        </Paper>
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ProductStockHistoryList;
