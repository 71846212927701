import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import * as routes from '../utils/routes';
import { RegisterFields } from '../types';
import apiClient from '../services/apiClient';
import { CircularProgress, Divider } from '@mui/material';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftPanel = styled.div`
  width: 50%;
  height: 100%;
  background-color: #e5f8ff;
  display: flex;
  flex-direction: column;
  padding: 50px;
  justify-content: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightPanel = styled.div`
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    min-height: 100vh;
  }
`;

const RegisterForm = styled.div`
  width: 80%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

const FormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputField = styled.input`
  background-color: #f3f3f3;
  border: none;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
`;

const Button = styled.button`
  padding: 20px;
  width: 100%;
  margin-top: 30px;
  border-radius: 8px;
  border: none;
  box-shadow: 1px 5px 2px 0px rgb(120 120 120 / 12%);
  background-color: black;
  color: white;
  cursor: pointer;
`;

const Logo = styled.p`
  font-size: 30px;
  margin-bottom: 50px;
  position: absolute;
  top: 30px;
  font-family: 'Noto Sans', sans-serif;
  font-family: 'Red Hat Display', sans-serif;
  color: black;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    `;

const initialState: RegisterFields = {
    firstname: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: '',
    shop_name: ''
};

export default function Register() {
    const [isPosting, setIsPosting] = React.useState(false);
    const [registerFields, setLoginFields] = React.useState<RegisterFields>(initialState);

    const navigate = useNavigate();

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        setIsPosting(true);

        apiClient.auth.register(registerFields)
            .then(() => {
                setIsPosting(false);
                navigate(routes.DASHBOARD);
            })
            .catch(() => {
                setIsPosting(false);
            });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLoginFields({ ...registerFields, [e.target.name]: e.target.value });
    };

    return (
        <Wrapper>
            <LeftPanel>
                <Logo>VendReady</Logo>
                <p style={{ fontSize: '25px', lineHeight: '40px', width: '75%' }}>
                    VendReady is an easy way to manage your inventory, keep track of suppliers and certifications.
                </p>
            </LeftPanel>
            <RightPanel>
                <RegisterForm>
                    <p style={{ fontSize: '30px', margin: '0px' }}>Create An Account</p>
                    <p style={{ width: "80%", textAlign: 'center', marginBottom: "120px" }}>
                        Create an account to start managing your inventory!
                    </p>
                    <Row>
                        <FormGroup style={{ marginRight: '10px' }}>
                            <InputField placeholder="First Name" type="text" onChange={handleChange} name="firstname" value={registerFields.firstname} />
                        </FormGroup>
                        <FormGroup style={{ marginRight: '10px' }}>
                            <InputField placeholder="Last Name" type="text" onChange={handleChange} name="surname" value={registerFields.surname} />
                        </FormGroup>
                    </Row>
                    <FormGroup>
                        <InputField placeholder="Email Address" type="email" onChange={handleChange} name="email" value={registerFields.email} />
                    </FormGroup>
                    <FormGroup>
                        <InputField placeholder="Shop/Business Name" type="text" onChange={handleChange} name="shop_name" value={registerFields.shop_name} autoComplete='off' />
                    </FormGroup>
                    <Divider style={{ width: '50%', margin: '30px 0px' }} variant="inset" />
                    <FormGroup>
                        <InputField placeholder="Password" type="password" onChange={handleChange} name="password" value={registerFields.password} autoComplete='new-password' />
                    </FormGroup>
                    <FormGroup>
                        <InputField placeholder="Retype Password" type="password" onChange={handleChange} name="confirmPassword" value={registerFields.confirmPassword} autoComplete='new-password' />
                        {registerFields.password !== registerFields.confirmPassword && <p style={{ color: 'red', fontSize: '12px' }}>Passwords do not match</p>}
                    </FormGroup>
                    <Button onClick={handleSubmit}>
                        {isPosting ? <CircularProgress size={15} color="inherit" /> : "Register"}
                    </Button>
                    <span style={{ fontSize: '13px', marginTop: '10px' }}>
                        Already have an account? <Link to={routes.LOGIN}>Login</Link>
                    </span>
                </RegisterForm>
            </RightPanel>
        </Wrapper>
    );
}
