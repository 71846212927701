import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';

const Wrapper = styled.div`
    background-color:#f3f4f8;
    width:100%;
    height:100%;
    min-height:100vh;
    padding:20px 40px;
    box-sizing:border-box;
`;
export default function Assets(){
    return(
        <Wrapper>
            <Header 
            title="Assets"
            description="Create edit or delete assets here"
             />
        </Wrapper>
    )
}