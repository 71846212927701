import { OrderInterface, OrderProductInterface, ProductInterface, SidePanelInterface } from "../types";
import SidePanel from "./SidePanel";
import { useEffect, useState } from "react";
import OrderDetails from "./orders/OrderDetails";
import EditOrderForm from "./orders/EditOrderForm";
import { Button } from "./SharedComponents";
import apiClient from "../services/apiClient";

interface OrderModal extends Omit<SidePanelInterface, 'children'> {
    order?: OrderInterface | null,
    handleHidePanel: (order?: OrderInterface | null) => void
};

const fetchOrderProducts = (orderID: number) => {
    return apiClient.get(`/orders/${orderID}/products`);
}

export default function OrderModal(props: OrderModal) {
    const { order, handleHidePanel, ...sidePanelProps } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<OrderProductInterface[]>([]);
    const [totalOrderPrice, setTotalOrderPrice] = useState<number | null>(null);
    const [isOrderProductsLoading, setIsOrderProductsLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        if (order && order.id) {
            setIsOrderProductsLoading(true);
            fetchOrderProducts(order.id).then((response: any) => {
                setIsOrderProductsLoading(false);
                setProducts(response.data.products)
                setTotalOrderPrice(response.data.order_price_total)
            })
        } else {
            setIsEditing(true)
        }
    }, [])

    const hidePanel = (order: OrderInterface | null = null) => {
        setProducts([]);
        setTotalOrderPrice(null);
        // setIsEditing(false);
        if (order) {
            handleHidePanel(order);
        } else {
            handleHidePanel();
        }
    }


    return (
        <SidePanel {...sidePanelProps} handleHidePanel={() => hidePanel()}>
            {
                isEditing ? (
                    <EditOrderForm order={order} products={products} handleHidePanel={hidePanel} isOrderProductsLoading={isOrderProductsLoading} cancelEdit={() => setIsEditing(false)} />

                ) : (
                    <>
                        <OrderDetails order={order} isOrderProductsLoading={isOrderProductsLoading} products={products} totalOrderPrice={totalOrderPrice} />
                        <Button onClick={() => setIsEditing(true)}>Edit Order</Button>
                    </>
                )
            }

        </SidePanel>
    )
}